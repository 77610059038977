import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Imprint"
      keywords={[
        `coqoon`,
        `web services`,
        `rapid prototyping`,
        `pwa`,
        `web services`,
      ]}
    />
    <div className={"container"}>
      <h1>Privacy Policy</h1>
      <h2>1. An overview of data protection</h2>
      <h3>General information</h3>{" "}
      <p>
        The following information will provide you with an easy to navigate
        overview of what will happen with your personal data when you visit our
        website. The term „personal data“ comprises all data that can be used to
        personally identify you. For detailed information about the subject
        matter of data protection, please consult our Data Protection
        Declaration, which we have included beneath this copy.
      </p>
      <h3>Data recording on our website</h3>{" "}
      <p>
        <strong>
          Who is the responsible party for the recording of data on this website
          (i.e. the „controller“)?
        </strong>
      </p>{" "}
      <p>
        The data on this website is processed by the operator of the website,
        whose contact information is available under section „Information
        Required by Law“ on this website.
      </p>{" "}
      <p>
        <strong>How do we record your data?</strong>
      </p>{" "}
      <p>
        We collect your data as a result of your sharing of your data with us.
        This may, for instance be information you enter into our contact form.
      </p>{" "}
      <p>
        Our IT systems automatically record other data when you visit our
        website. This data comprises primarily technical information (e.g. web
        browser, operating system or time the site was accessed). This
        information is recorded automatically when you access our website.
      </p>{" "}
      <p>
        <strong>What are the purposes we use your data for?</strong>
      </p>{" "}
      <p>
        A portion of the information is generated to guarantee the error free
        provision of the website. Other data may be used to analyse your user
        patterns.
      </p>{" "}
      <p>
        <strong>
          What rights do you have as far as your information is concerned?
        </strong>
      </p>{" "}
      <p>
        You have the right to receive information about the source, recipients
        and purposes of your archived personal data at any time without having
        to pay a fee for such disclosures. You also have the right to demand
        that your data are rectified, blocked or eradicated. Please do not
        hesitate to contact us at any time under the address disclosed in
        section „Information Required by Law“ on this website if you have
        questions about this or any other data protection related issues. You
        also have the right to log a complaint with the competent supervising
        agency.
      </p>{" "}
      <p>
        Moreover, under certain circumstances, you have the right to demand the
        restriction of the processing of your personal data. For details, please
        consult the Data Protection Declaration under section „Right to
        Restriction of Data Processing.“
      </p>
      <h3>Analysis tools and tools provided by third parties</h3>{" "}
      <p>
        There is a possibility that your browsing patterns will be statistically
        analysed when your visit our website. Such analyses are performed
        primarily with cookies and with what we refer to as analysis programmes.
        As a rule, the analyses of your browsing patterns are conducted
        anonymously; i.e. the browsing patterns cannot be traced back to you.
      </p>{" "}
      <p>
        You have the option to object to such analyses or you can prevent their
        performance by not using certain tools. For detailed information about
        the tools and about your options to object, please consult our Data
        Protection Declaration below.
      </p>
      <h2>2. General information and mandatory information</h2>
      <h3>Data protection</h3>{" "}
      <p>
        The operators of this website and its pages take the protection of your
        personal data very seriously. Hence, we handle your personal data as
        confidential information and in compliance with the statutory data
        protection regulations and this Data Protection Declaration.
      </p>{" "}
      <p>
        Whenever you use this website, a variety of personal information will be
        collected. Personal data comprises data that can be used to personally
        identify you. This Data Protection Declaration explains which data we
        collect as well as the purposes we use this data for. It also explains
        how, and for which purpose the information is collected.
      </p>{" "}
      <p>
        We herewith advise you that the transmission of data via the Internet
        (i.e. through e-mail communications) may be prone to security gaps. It
        is not possible to completely protect data against third party access.
      </p>
      <h3>
        Information about the responsible party (referred to as the „controller“
        in the GDPR)
      </h3>{" "}
      <p>The data processing controller on this website is:</p>{" "}
      <p>
        COQOON - Benjamin Gröner
        <br />
        Lenzhalde 31
        <br />
        70192 Stuttgart
      </p>
      <p>
        Phone: +49 (0)152 52601467
        <br />
        E-mail: hello@coqoon.com
      </p>
      <p>
        The controller is the natural person or legal entity that
        single-handedly or jointly with others makes decisions as to the
        purposes of and resources for the processing of personal data (e.g.
        names, e-mail addresses, etc.).
      </p>
      <h3>Revocation of your consent to the processing of data</h3>{" "}
      <p>
        A wide range of data processing transactions are possible only subject
        to your express consent. You can also revoke at any time any consent you
        have already given us. To do so, all you are required to do is sent us
        an informal notification via e-mail. This shall be without prejudice to
        the lawfulness of any data collection that occurred prior to your
        revocation.
      </p>
      <h3>
        Right to object to the collection of data in special cases; right to
        object to direct advertising (Art. 21 GDPR)
      </h3>{" "}
      <p>
        <strong>
          In the event that data are processed on the basis of Art. 6 Sect. 1
          lit. e or f GDPR, you have the right to at any time object to the
          processing of your personal data based on grounds arising from your
          unique situation. This also applies to any profiling based on these
          provisions. To determine the legal basis, on which any processing of
          data is based, please consult this Data Protection Declaration. If you
          log an objection, we will no longer process your affected personal
          data, unless we are in a position to present compelling protection
          worthy grounds for the processing of your data, that outweigh your
          interests, rights and freedoms or if the purpose of the processing is
          the claiming, exercising or defence of legal entitlements (objection
          pursuant to Art. 21 Sect. 1 GDPR).
        </strong>
      </p>{" "}
      <p>
        <strong>
          If your personal data is being processed in order to engage in direct
          advertising, you have the right to at any time object to the
          processing of your affected personal data for the purposes of such
          advertising. This also applies to profiling to the extent that it is
          affiliated with such direct advertising. If you object, your personal
          data will subsequently no longer be used for direct advertising
          purposes (objection pursuant to Art. 21 Sect. 2 GDPR).
        </strong>
      </p>
      <h3>Right to log a complaint with the competent supervisory agency</h3>{" "}
      <p>
        In the event of violations of the GDPR, data subjects are entitled to
        log a complaint with a supervisory agency, in particular in the member
        state where they usually maintain their domicile, place of work or at
        the place where the alleged violation occurred. The right to log a
        complaint is in effect regardless of any other administrative or court
        proceedings available as legal recourses.
      </p>
      <h3>Right to data portability</h3>{" "}
      <p>
        You have the right to demand that we hand over any data we automatically
        process on the basis of your consent or in order to fulfil a contract be
        handed over to you or a third party in a commonly used, machine readable
        format. If you should demand the direct transfer of the data to another
        controller, this will be done only if it is technically feasible.
      </p>
      <h3>SSL and/or TLS encryption</h3>{" "}
      <p>
        For security reasons and to protect the transmission of confidential
        content, such as purchase orders or inquiries you submit to us as the
        website operator, this website uses either an SSL or a TLS encryption
        programme. You can recognise an encrypted connection by checking whether
        the address line of the browser switches from „http://“ to „https://“
        and also by the appearance of the lock icon in the browser line.
      </p>{" "}
      <p>
        If the SSL or TLS encryption is activated, data you transmit to us
        cannot be read by third parties.
      </p>
      <h3>
        Information about, blockage, rectification and eradication of data
      </h3>{" "}
      <p>
        Within the scope of the applicable statutory provisions, you have the
        right to at any time demand information about your archived personal
        data, their source and recipients as well as the purpose of the
        processing of your data. You may also have a right to have your data
        rectified, blocked or eradicated. If you have questions about this
        subject matter or any other questions about personal data, please do not
        hesitate to contact us at any time at the address provided in section
        „Information Required by Law.“
      </p>
      <h3>Right to demand processing restrictions</h3>{" "}
      <p>
        You have the right to demand the imposition of restrictions as far as
        the processing of your personal data is concerned. To do so, you may
        contact us at any time at the address provided in section „Information
        Required by Law.“ The right to demand restriction of processing applies
        in the following cases:
      </p>{" "}
      <ul>
        {" "}
        <li>
          In the event that you should dispute the correctness of your data
          archived by us, we will usually need some time to verify this claim.
          During the time that this investigation is ongoing, you have the right
          to demand that we restrict the processing of your personal data.
        </li>{" "}
        <li>
          If the processing of your personal data was/is conducted in an
          unlawful manner, you have the option to demand the restriction of the
          processing of your data in lieu of demanding the eradication of this
          data.
        </li>{" "}
        <li>
          If we do not need your personal data any longer and you need it to
          exercise, defend or claim legal entitlements, you have the right to
          demand the restriction of the processing of your personal data instead
          of its eradication.
        </li>{" "}
        <li>
          If you have raised an objection pursuant to Art. 21 Sect. 1 GDPR, your
          rights and our rights will have to be weighed against each other. As
          long as it has not been determined whose interests prevail, you have
          the right to demand a restriction of the processing of your personal
          data.
        </li>{" "}
      </ul>{" "}
      <p>
        If you have restricted the processing of your personal data, these data
        – with the exception of their archiving – may be processed only subject
        to your consent or to claim, exercise or defend legal entitlements or to
        protect the rights of other natural persons or legal entities or for
        important public interest reasons cited by the European Union or a
        member state of the EU.
      </p>
      <h2>3. Recording of data on our website</h2>
      <h3>Cookies</h3>{" "}
      <p>
        In some instances, our website and its pages use so-called cookies.
        Cookies do not cause any damage to your computer and do not contain
        viruses. The purpose of cookies is to make our website more user
        friendly, effective and more secure. Cookies are small text files that
        are placed on your computer and stored by your browser.
      </p>{" "}
      <p>
        Most of the cookies we use are so-called „session cookies.“ They are
        automatically deleted after your leave our site. Other cookies will
        remain archived on your device until you delete them. These cookies
        enable us to recognise your browser the next time you visit our website.
      </p>{" "}
      <p>
        You can adjust the settings of your browser to make sure that you are
        notified every time cookies are placed and to enable you to accept
        cookies only in specific cases or to exclude the acceptance of cookies
        for specific situations or in general and to activate the automatic
        deletion of cookies when you close your browser. If you deactivate
        cookies, the functions of this website may be limited.
      </p>{" "}
      <p>
        Cookies that are required for the performance of the electronic
        communications transaction or to provide certain functions you want to
        use (e.g. the shopping cart function), are stored on the basis of Art. 6
        Sect. 1 lit. f GDPR. The website operator has a legitimate interest in
        storing cookies to ensure the technically error free and optimised
        provision of the operator’s services. If other cookies (e.g. cookies for
        the analysis of your browsing patterns) should be stored, they are
        addressed separately in this Data Protection Declaration.
      </p>
      <h3>Contact form</h3>{" "}
      <p>
        If you submit inquiries to us via our contact form, the information
        provided in the contact form as well as any contact information provided
        therein will be stored by us in order to handle your inquiry and in the
        event that we have further questions. We will not share this information
        without your consent.
      </p>{" "}
      <p>
        Hence, the processing of the data entered into the contact form occurs
        exclusively based on your consent (Art. 6 Sect. 1 lit. a GDPR). You have
        the right to revoke at any time any consent you have already given us.
        To do so, all you are required to do is sent us an informal notification
        via e-mail. This shall be without prejudice to the lawfulness of any
        data collection that occurred prior to your revocation.
      </p>{" "}
      <p>
        The information you have entered into the contact form shall remain with
        us until you ask us to eradicate the data, revoke your consent to the
        archiving of data or if the purpose for which the information is being
        archived no longer exists (e.g. after we have concluded our response to
        your inquiry). This shall be without prejudice to any mandatory legal
        provisions – in particular retention periods.
      </p>
      <h3>Request by e-mail, telephone or fax</h3>{" "}
      <p>
        If you contact us by e-mail, telephone or fax, your request, includin©g
        all resulting personal data (name, request) will be stored and processed
        by us for the purpose of processing your request. We do not pass these
        data on without your consent.
      </p>{" "}
      <p>
        The processing of these data is based on Art. 6 para. 1 lit. b GDPR, if
        your request is related to the execution of a contract or if it is
        necessary to carry out pre-contractual measures. In all other cases, the
        processing is based on your consent (Article 6 (1) a GDPR) and/or on our
        legitimate interests (Article 6 (1) (f) GDPR), since we have a
        legitimate interest in the effective processing of requests addressed to
        us.
      </p>{" "}
      <p>
        The data sent by you to us via contact requests remain with us until you
        request us to delete, revoke your consent to the storage or the purpose
        for the data storage lapses (e.g. after completion of your request).
        Mandatory statutory provisions - in particular statutory retention
        periods - remain unaffected.
      </p>
      <h2>4. Analysis tools and advertising</h2>
      <h3>Google Analytics</h3>{" "}
      <p>
        This website uses functions of the web analysis service Google
        Analytics. The provider of this service is Google Ireland Limited
        („Google“), Gordon House, Barrow Street, Dublin 4, Ireland.
      </p>{" "}
      <p>
        Google Analytics uses so-called cookies. Cookies are text files, which
        are stored on your computer and that enable an analysis of the use of
        the website by users. The information generated by cookies on your use
        of this website is usually transferred to a Google server in the United
        States, where it is stored.
      </p>{" "}
      <p>
        The storage of Google Analytics cookies and the utilization of this
        analysis tool are based on Art. 6 Sect. 1 lit. f GDPR. The operator of
        this website has a legitimate interest in the analysis of user patterns
        to optimize both, the services offered online and the operator’s
        advertising activities.
      </p>{" "}
      <p>
        <strong>IP anonymization</strong>
      </p>{" "}
      <p>
        On this website, we have activated the IP anonymization function. As a
        result, your IP address will be abbreviated by Google within the member
        states of the European Union or in other states that have ratified the
        Convention on the European Economic Area prior to its transmission to
        the United States. The full IP address will be transmitted to one of
        Google’s servers in the United States and abbreviated there only in
        exceptional cases. On behalf of the operator of this website, Google
        shall use this information to analyse your use of this website to
        generate reports on website activities and to render other services to
        the operator of this website that are related to the use of the website
        and the Internet. The IP address transmitted in conjunction with Google
        Analytics from your browser shall not be merged with other data in
        Google’s possession.
      </p>
      <p>
        <strong>Browser plug-in</strong>
      </p>{" "}
      <p>
        You do have the option to prevent the archiving of cookies by making
        pertinent changes to the settings of your browser software. However, we
        have to point out that in this case you may not be able to use all of
        the functions of this website to their fullest extent. Moreover, you
        have the option prevent the recording of the data generated by the
        cookie and affiliated with your use of the website (including your IP
        address) by Google as well as the processing of this data by Google by
        downloading and installing the browser plug-in available under the
        following link:{" "}
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://tools.google.com/dlpage/gaoptout?hl=en
        </a>
        .
      </p>{" "}
      <p>
        <strong>Objection to the recording of data</strong>
      </p>{" "}
      <p>
        You have the option to prevent the recording of your data by Google
        Analytics by clicking on the following link. This will result in the
        placement of an opt out cookie, which prevents the recording of your
        data during future visits to this website:{" "}
        <button onClick="javascript:gaOptout();">
          Google Analytics deactivation
        </button>
        .
      </p>{" "}
      <p>
        For more information about the handling of user data by Google
        Analytics, please consult Google’s Data Privacy Declaration at:{" "}
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://support.google.com/analytics/answer/6004245?hl=en
        </a>
        .
      </p>
      <p>
        <strong>Contract data processing</strong>
      </p>{" "}
      <p>
        We have executed a contract data processing agreement with Google and
        are implementing the stringent provisions of the German data protection
        agencies to the fullest when using Google Analytics.
      </p>
      <p>
        <strong>Archiving period</strong>
      </p>{" "}
      <p>
        Data on the user or incident level stored by Google linked to cookies,
        user IDs or advertising IDs (e.g. DoubleClick cookies, Android
        advertising ID) will be anonymized or deleted after 14 month. For
        details please click the following link:{" "}
        <a
          href="https://support.google.com/analytics/answer/7667196?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://support.google.com/analytics/answer/7667196?hl=en
        </a>
      </p>
      <h2>5. Plug-ins and Tools</h2>
      <h3>Google Web Fonts (local embedding)</h3>{" "}
      <p>
        This website uses so-called Web Fonts provided by Google to ensure the
        uniform use of fonts on this site. These Google fonts are locally
        installed so that a connection to Google’s servers will not be
        established in conjunction with this application.
      </p>
    </div>
  </Layout>
)

export default IndexPage
